.quote {
    border: 3px dashed #7b7b7b;
    position: relative;
    padding: 45px;
    border-radius: 40px 0px 39px 0px;
    background: #e6e6e6;
    min-height: 140px;
    box-shadow: 6px -7px 2px #c3c3c3;

    &:before{
        position: absolute;
        content: ",,";
        font-size: 139px;
        letter-spacing: -8px;
        right: 76px;
        bottom: -65px;
        z-index: 1;
    }
    &:after{
        position: absolute;
        content: "";
        background: #ffffff;
        width: 80px;
        height: 48px;
        right: 64px;
        bottom: -3px;
        border-radius: 0% 20px 0% 0%;
        border: 3px dashed #7b7b7b;
        border-bottom: 0;
        box-shadow: -1px 5px 5px #c3c3c3 inset;
    }
}