@charset "UTF-8";
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

// Settings
// ==================================================
$hamburger-padding-x: 15px !default;
$hamburger-padding-y: 10px !default;
$hamburger-layer-width: 30px !default;
$hamburger-layer-height: 3px !default;
$hamburger-layer-spacing: 4px !default;
$hamburger-layer-color: #000 !default;
$hamburger-layer-border-radius: 4px !default;
$hamburger-hover-opacity: 0.7 !default;
$hamburger-hover-transition-duration: 0.15s !default;
$hamburger-hover-transition-timing-function: linear !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter: opacity(50%) !default;

// Types (Remove or comment out what you don’t need)
// ==================================================
$hamburger-types: (collapse, slider) !default;

// Base Hamburger (We need this)
// ==================================================
@import "base";

// Hamburger types
// ==================================================
@import "types/collapse";
@import "types/slider";

// ==================================================
// Cooking up additional types:
//
// The Sass for each hamburger type should be nested
// inside an @if directive to check whether or not
// it exists in $hamburger-types so only the CSS for
// included types are generated.
//
// e.g. hamburgers/types/_new-type.scss
//
// @if index($hamburger-types, new-type) {
//   .hamburger--new-type {
//     ...
//   }
// }

.main-menu {
	> ul {
		@include horizontal-menu(center, 45px);
		// @include drop-down($padding: vr(1) / 2 0);
		padding: 0;
		font-size: rem-calc(12);

		> li {
			transition: background-color 150ms linear;

			> a {
				color: $white;
				transition: color 150ms linear;
				text-transform: uppercase;
				font-weight: 500;
			}

			&:hover {
				// background-color: $brand-primary;

				> a {
					color: $gray;
				}
			}
		}

		// .sub-menu {
		// 	background-color: $white;
		// 	box-shadow: 10px 15px 35px 0 rgba(50, 50, 93, 0.1),
		// 		10px 10px 25px 0 rgba(0, 0, 0, 0.07);

		// 	a {
		// 		color: $brand-primary;
		// 		transition: background-color 250ms ease-in-out,
		// 			color 250ms ease-in-out;

		// 		&:hover {
		// 			color: rgba(#fff, 0.9);
		// 			background-color: $brand-primary;
		// 		}
		// 	}
		// }
	}
}

// Mobile menu
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
	background: $brand-primary;
}

// Connect menu item seperators to menu edge
.mm-listitem:after {
	@include left(0);
}
