@mixin sharta($position: after, $width: 60, $color: $brand-primary) {
	position: relative;
	margin-bottom: 40px;

	&:#{$position} {
		content: '';
		display: inline-block;
		width: rem-calc($width);
		height: 1px;
		background-color: $color;

		position: absolute;
		@include left(0);
		bottom: -20px;
	}
}