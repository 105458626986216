// Typography Helpers
.text-center {
	text-align: center;
}

.text-right {
	@include text-align(right);
}

.text-left {
	@include text-align(left);
}

.upper-case {
	text-transform: uppercase;
}

// Background
.bg-primary {
	background-color: $brand-primary;
}

.bg-secondary {
	background-color: $brand-secondary;
}

.bg-shade {
	background-color: $brand-shade;
}

.bg-gray-base {
	background-color: $gray-base;
}

.bg-gray-light {
	background-color: $gray-light;
}

.bg-gray-lighter {
	background-color: $gray-lighter;
}

// Special colors
.bg-carolina {
	background-color: $brand-carolina;
}

.bg-purple {
	background-color: $brand-purple;
}

.bg-dark-purple {
	background-color: $brand-dark-purple;
}

.bg-pink {
	background-color: $brand-pink;
}

.bg-dark-pink {
	background-color: $brand-dark-pink;
}

.lengend-color-box {
	width: 8px;
	height: 8px;
	background-color: $brand-primary;
}

// Colors
.color-primary {
	color: $brand-primary;
}

.color-secondary {
	color: $brand-secondary;
}

// Positioning
.fill-parent {
	position: absolute;
	width: 100%;
	top: 0;
	bottom: 0;
}

.full-height {
	height: 100%;
}

.is_stuck {
	z-index: 10;
}

// Vertical Alignment - Ghost Element
.v-center-parent {
	display: flex;
}

.v-center-child {
	display: inline-block;
	width: 100%;
	align-self: center;
}

// Columns
.mc-2 {
	@include mappy-bp(xsmall) {
		column-count: 2;
	}
}

// Spacing Helpers
.mb-0 {
	margin-bottom: 0 !important;
}

.mb-fraction {
	margin-bottom: vr(0.1) !important;

	&--sm {
		@include mappy-bp(max small) {
			margin-bottom: vr(0.1) !important;
		}
	}

	&--md {
		@include mappy-bp(max medium) {
			margin-bottom: vr(0.1) !important;
		}
	}
}

.mb-half {
	margin-bottom: vr(0.5) !important;

	&--sm {
		@include mappy-bp(max small) {
			margin-bottom: vr(0.5) !important;
		}
	}

	&--md {
		@include mappy-bp(max medium) {
			margin-bottom: vr(0.5) !important;
		}
	}
}

.mb-1 {
	margin-bottom: vr(1) !important;

	&--sm {
		@include mappy-bp(max small) {
			margin-bottom: vr(1) !important;
		}
	}

	&--md {
		@include mappy-bp(max medium) {
			margin-bottom: vr(1) !important;
		}
	}
}

.mb-2 {
	margin-bottom: vr(2) !important;

	&--sm {
		@include mappy-bp(max small) {
			margin-bottom: vr(2) !important;
		}
	}

	&--md {
		@include mappy-bp(max medium) {
			margin-bottom: vr(2) !important;
		}
	}
}

.mt-0 {
	margin-top: 0;
}

.no-pad {
	padding: 0 !important;
}

.p-1 {
	padding: vr(1) !important;
}

.pt-0 {
	padding-top: 0 !important;
}

.pt-30 {
	padding-top: 30px !important;
}

.pb-0 {
	padding-bottom: 0 !important;
}

.pb-half {
	padding-bottom: vr(0.5) !important;
}

.pb-1 {
	padding-bottom: vr(1) !important;
}

// Play Overlay
.icon-overlay {
	position: relative;

	&__icon {
		height: 72px;
		width: 72px;
		left: 50%;
		top: 50%;
		margin-left: -36px;
		margin-top: -36px;
		position: absolute;
		cursor: pointer;

		&--play {
			background: url("../img/icons/play.png") no-repeat;
		}
	}
}

#section-anchor {
	height: 117px;
	margin-top: -115px;
	visibility: hidden;
	@include mappy-bp(max large) {
		max-width: 107px;
		margin-top: -107px;
	}

	@include mappy-bp(max small) {
		height: 102px;
		margin-top: -100px;
	}
}

.sharta, .sharta--middle, .sharta--gray, .sharta--pb-sm, .sharta--sm {
	&:after {
		content:'';
		display: block;
		width: 100%;
		height: 7px;
		border-top: 4px solid $gray-base;
		border-bottom: 1px solid $gray-base;
	}
}

.sharta--sm {
	padding-top: vr(1);
	padding-bottom: vr(2);
}

.sharta--pb-sm {
	padding-bottom: vr(1);
}

.sharta--gray:after {
	border-color: $gray-light;
}

.sharta--middle {
	position: relative;
	margin-bottom: 0;

	&:after {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: -1;
	}

	span {
		background-color: $white;
		@include padding-right(30px);
	}
}
.our-work-section .our-work-box:nth-child(n+7) {
    display:none;
}

.play-button:after {
	content: '';
	position: absolute;
	background-image: url("../img/work/play-button.png");
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 38px;
	height: 45px;
	z-index: 1;
}


.form-uploaad {
    border-bottom: 1px solid #c3c3c3;
	padding-bottom: 10px;
	margin-top: 20px;
	.uploadButton {
		position: relative;
		display: flex;
	}

	input[type="text"] {
		border: 0px ;
	}

	span {
		background: #000;
		border: 0;
		color: #fff;
		padding: 0 20px;
		text-align: center;
		line-height: 40px;
		cursor: pointer;
		border-radius: 30px;
	}

	input[type="file"] {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		opacity: 0;
		cursor: pointer;
		width: 100%;
	}
}
.submit-btn {
	background: #000;
	margin-top: 10px;
	padding: 18px;
	text-align: center;
	color: #fff;
	button {
		background: transparent;
		border: 0;
	}
}
