.kpi {
	&__number {
		font-size: 98px;
		display: inline-block;
		@include padding-right(40px);
		vertical-align: middle;
		margin-bottom: 0;
		width: 50%;
	}

	&__desc {
		display: inline-block;
		vertical-align: middle;
		margin-bottom: 0;
		font-weight: bold;
	}
}
