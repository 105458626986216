@import "../vendors/typiConfig";

body {
	font-size: rem-calc(16);
	line-height: inherit;
	font-weight: 300;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
	font-family: $headings-font-family;
	font-weight: $headings-font-weight;
	margin-top: 0;
	margin-bottom: vr(0.75);

	&.dark,
	.dark & {
		color: #fff;
	}

	&.huge {
		@include typi("huge");
	}

	.meta {
		display: inline-block;
		padding: 0 $padding-xs-horizontal;
		color: $gray-light;
		font-size: 0.6em;
		margin-top: rem-calc(12);

		@include float(right);
	}

	&.branded {
		border-bottom: 1px solid $brand-primary;
		padding-bottom: vr(1) / 2;
	}
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
	color: $gray-base;
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
	font-weight: normal;
}

h1,
.h1 {
	@include typi("h1");

	&.huge {
		@include typi("huge");
	}

	&.large {
		@include typi("h1Large");
	}
}

h2,
.h2 {
	@include typi("h2");
}

h3,
.h3 {
	@include typi("h3");
}

h4,
.h4 {
	@include typi("h4");
}

h5,
.h5 {
	@include typi("h5");
}

h6,
.h6 {
	@include typi("h6");
	font-weight: bold;
}

h1.large,
.h1.large {
	@include typi("h1Large");

	text-transform: uppercase;
	font-weight: 600;
}

ul {
	&.clean {
		@include clean-menu;

		padding: 0;
	}

	&.list-one {
		> li {
			font-size: rem-calc(14);
			font-weight: bold;
			margin-bottom: vr(0.3);
			letter-spacing: 0.5px;
		}
	}

	&.list-two {
		> li {
			font-size: rem-calc(14);
			font-weight: bold;
			margin-bottom: vr(0.3);
			letter-spacing: 0.5px;
		}

		.sub-menu {
			@include clean-menu;

			margin-top: vr(0.75);
			padding: 0;

			li {
				font-weight: normal;
				margin-bottom: vr(0.3);
			}
		}
	}
}

p {
	margin-top: 0;
	margin-bottom: vr(1);

	&:last-child {
		margin-bottom: 0;
	}

	&.dark,
	.dark & {
		color: #fff;
	}

	&.text-large,
	.text-large & {
		@include typi("h6");
	}

	&.lead {
		@include typi("h4");
	}

	&.meta {
		color: $gray-dark;
		font-size: $font-size-small;
	}

	&.bullet {
		span {
			display: inline-block;
			padding: $padding-xs-vertical $padding-base-horizontal;
			border-radius: $border-radius-base;
		}

		&--primary span {
			background: $brand-primary;
		}

		&--secondary span {
			background: $brand-secondary;
		}

		&--sm span {
			padding: $padding-xs-vertical $padding-small-horizontal;
		}
	}

	+ p,
	+ ul,
	+ ol {
		margin-top: vr(1);
	}
}

a {
	outline: none;
	color: inherit;

	&:active,
	&:visited,
	&:focus {
		outline: none;
	}

	.dark & {
		color: #fff;

		&:hover,
		&:focus {
			color: #fff;
		}
	}

	&.text-large,
	.text-large & {
		font-size: ms(1);
		font-weight: normal;
	}
}

select {
	&.form-control {
		background: #fff;
		color: mix($brand-primary, $white, 90%);
		box-shadow: none;
		border-color: $brand-primary;
		height: 45px;

		&:focus {
			box-shadow: none;
			border-color: $brand-secondary;
			color: $brand-secondary;
		}
	}
}

table {
	&.dark,
	.dark & {
		color: #fff;
	}
}

.sharted {
	@include sharta($position: "before");

	&.dark,
	.dark & {
		@include sharta($color: white, $position: "before");
	}
}

.main-content {
	p,
	ul,
	ol {
		margin-bottom: vr(1);
	}
}
