// Fix FOUC of images
.slider img {
	display: inline-block;
	vertical-align: bottom;
	visibility: hidden;
	opacity: 0;
	transition: visibility 0.2s linear, opacity 0.2s linear;
}
.slick-initialized img{
	visibility: visible;
	opacity: 1;
}
// Fix FOUC of images //

// Caption box customization
.slider-container {
	position: relative;
}

.slider-box-container {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 100%;

	@include mappy-bp(max medium) {
		display: none;
	}
}

.slider-box {
	max-width: 500px;

	&__title {
		background-color: rgba($gray-base, 0.9);
		font-family: serif;
		padding: vr(0.75);
		margin-bottom: 0;
	}

	&__caption {
		background-color: rgba($white, 0.9);
		padding: vr(0.75);
		margin-bottom: 0;
	}
}

// Caption box customization //

// Dots customization
.caption-slider, .image-gallery {
	.slick-dotted {
		margin-bottom: 0;
	}

	.slick-dots {
		@include text-align(left);
		width: 500px;
		bottom: -10px;
		margin: 0 -2.5px;
		li {
			@include float(left);
			margin: 0;
			width: 25%;
			height: 5px;
			@include padding-right(2.5px);
			@include padding-left(2.5px);

			// &:first-child {
				// 	@include padding-left(0);
				// }
				// &:last-child {
					// 	@include padding-right(0);
					// }

					button {
						height: 5px;
						position: relative;
						width: 100%;
						padding: 0;
					}

					button:before {
						height: 5px;
						width: 100%;
						background-color: #fff;
						transition: opacity 0.2s linear;
				content: '';
			}
		}
	}
}

.image-gallery {
	.slick-dots {
		width: 100%;
		padding: 0 10px 0 15px;
		bottom: -20px;

		li {
			button:before {
				background-color: #000;
			}
		}
	}

}

// Dots customization //
