.icon-box {
	padding: 15px;
	transition: background-color 0.2s ease-in-out;

	&:hover {
		background-color: $white;
	}

	&__image {
		text-align: center;
	}

	&__content {
		text-align: center
	}
}
