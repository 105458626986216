.social-panel {
    position: fixed;
    top:3%;
    @media only screen and (max-width: 990px){
        position: relative;
        top: -3px;
        right: 3%;
    }
}
.social-menu.list-inline{
        list-style: none;
        margin: 0;
        text-align: right;
        li{
            display: inline-block;
            vertical-align: middle;
            margin-left: 45px;  
        }
    }
