$breakpoints: (
	xsmall: 580px,
	small: 768px,
	medium: 992px,
	large: 1200px,
	xlarge: 1600px
);

$typi-breakpoint: mappy-bp;

$typi: (
	base: (null: (16px, 1.6)),
	h1: (null: (ms(6), 1.3), small: (ms(8))),
	h2: (null: (ms(5), 1.4), small: (ms(6))),
	h3: (null: (ms(4), 1.5), small: (ms(5))),
	h4: (null: (ms(3), 1.6), small: (ms(3))),
	h5: (null: (ms(2), 1.6), small: (ms(2))),
	h6: (null: (ms(0), 1.7), medium: (ms(0))),
	h1Large: (null: (ms(6), 1.3), small: (ms(9))),
	huge: (null: (ms(7), 1.3), small: (ms(9)), large: (ms(10)))
);

@include typi-init;
