.overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	background: rgba($black, 0.8);

	&__content {
		position: relative;

		h1,
		.h1,
		h2,
		.h2,
		h3,
		.h3,
		h4,
		.h4,
		h5,
		.h5,
		h6,
		.h6,
		p,
		a,
		td {
			color: $white;
		}
	}

	&--secondary {
		background: rgba($brand-secondary, 0.9);
	}

	&--primary-gradient {
		// position: relative;
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#4e5f57+0,4e5f57+100&1+30,0.6+100 */
		background: -moz-linear-gradient(
			left,
			rgba(78, 95, 87, 1) 0%,
			rgba(78, 95, 87, 1) 30%,
			rgba(78, 95, 87, 0.6) 100%
		); /* FF3.6-15 */
		background: -webkit-linear-gradient(
			left,
			rgba(78, 95, 87, 1) 0%,
			rgba(78, 95, 87, 1) 30%,
			rgba(78, 95, 87, 0.6) 100%
		); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(
			to right,
			rgba(78, 95, 87, 1) 0%,
			rgba(78, 95, 87, 1) 30%,
			rgba(78, 95, 87, 0.6) 100%
		); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4e5f57', endColorstr='#994e5f57',GradientType=1 ); /* IE6-9 */
	}

	&--blurred {
		filter: blur(5px);
		top: -10px;
		right: -10px;
		bottom: -10px;
		left: -10px;

		&:before {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background: rgba($black, 0.5);
		}
	}
}

.blurred-wrapper {
	overflow: hidden;
}


.section-special:hover .overlay-video{
	opacity: 1;
	transform: scale(1);
}

.overlay-video {
	position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
	height: 200%;
	opacity: 0;
	transform: scale(1.2);
	transition: all 0.2s ease-in-out;

	video {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		margin: auto;
		min-height: 50%;
		min-width: 50%;
	}

	&.stretch-verticaly {
		left: -90%;

		video {
			min-height: 70%;
		}
	}
}

.overlay-caption {
	position: absolute;

	h1, h2 {
		line-height: 1;
	}

	h1, h2, h3, h4, h5, h6 {
		color: $white;
		text-transform: uppercase;

	}

	p {
		color: $white;
	}

	.huge {
		margin-bottom: 0;
	}

	h5 {
		margin-bottom: 0;
	}

	h2 {
		margin-bottom: 10px;
	}

	&--center {
		top: 50%;
		left: 30px;
		// width: 30%;
		transform: translateY(-50%);
	}

	&--bottom {
		bottom: 30px;
		left: 30px;
		// width: 50%;
	}

	&--right {
		bottom: 30px;
		right: 30px;
		// width: 40%;
	}

}
