.header {
	position: fixed;
	width: 100%;
	top: 0;
	background: $white;
	padding-top: rem-calc(15);
	// padding-left: 0;
	// padding-right: 0;

	transition: transform 0.4s ease, background-color 0.3s ease-in-out,
		padding 0.3s ease-in-out, box-shadow 0.4s ease;
	z-index: 5;

	.logo {
		@include text-align(left);
		padding-bottom: rem-calc(15);
	}

	.search {
		@include mappy-bp(max medium) {
			display: none;
		}

		padding-top: rem-calc(12);
		input {
			width: 200px;
		}
	}

	.menus-container {
		background-color: $gray-base;
	}

	.main-menu {
		display: none;

		@include mappy-bp(medium) {
			display: block;
			padding: rem-calc(20) 0;
		}
	}

	.top-menu-item {
		@include mappy-bp(medium) {
			display: none;
		}
	}

	.mobile-menu {
		@include text-align(right);

		@include margin-right(-15px);

		margin-top: rem-calc(15);

		@include mappy-bp(medium) {
			display: none;
		}
	}

	&.scrolled {
		box-shadow: 5px 10px 25px 0 rgba(50, 50, 93, 0.1),
			5px 5px 15px 0 rgba(0, 0, 0, 0.07);
	}
}


.mm-panels, .mm-menu a, .mm-menu a:hover {
	background: $gray-base;
	color: $white;

	.search {
		input {
			padding: 12px 10px 12px 20px;
			background-color: $gray-base;
			color: white;
			padding-right: 35px;
		}
		a {
			position: absolute;
			right: 15px;
			top: 15px;
			background-image: url('../img/search-white.png') !important;
			content:'';
			width: 16px;
			height: 17px;
			padding: 0;

			img {
				display: none;
			}
		}
	}
}


.mm-listitem:after {
	border-color: $white;
}
