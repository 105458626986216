.media-box {
	position: relative;
	width: 100%;
	margin-bottom: vr(1);
	padding-bottom: 50px;
	display: inline-block;

	&--brd-btm {
		border-bottom: 1px solid $gray-base;
		padding-bottom: 0;
	}

	&__image {
		margin-bottom: vr(1);
		padding: 15px;
		text-align: center;
		background-color: $gray-light;
		filter: grayscale(1);
		transition: filter 0.2s ease-in-out, background-color 0.2s ease-in-out;

		&:hover {
			background-color: $gray-lighter;
			filter: none;
		}

		&:not(.person):after {
			content: '';
			height: 100%;
			vertical-align: middle;
			display: inline-block;
		}

		&:not(.person) img {
			vertical-align: middle;
		}

		&.person {
			padding: 0;
		}

	}

	&__meta--title {
		@include float(left);
		width: 50%;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		margin-bottom: 0;
		font-weight: bold;
	}

	&__meta--category {
		@include float(right);
		margin-bottom: 0;
		padding-top: 4px;
	}

	&__desc[aria-expanded="false"] {
		display: block;
		height: 50px !important;
		overflow: hidden;
	}

	&__desc.collapsing[aria-expanded="false"] {
		height: 50px !important;
	}


	&__title {
		font-weight: bold;
		color: $gray-dark;

	}


	&__link {
		position: absolute;
		bottom: 0;
		border-top: 1px solid $gray-base;
		font-weight: bold;
		width: 100%;
		padding-top: 5px;
		text-transform: uppercase;
		font-size: 12px;


		&--accordion {
			a.collapsed:after {
				content: "Read More"
			}

			a:not(.collapsed):after {
				content: "Read Less"
			}
		}
	}

}
