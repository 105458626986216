form {
	/* Just to center the form on the page */
	margin: 0 auto;
	/* To see the outline of the form */
}

form div div div + div {
	margin-top: vr(1);
}

label {
	/* To make sure that all labels have the same size and are properly aligned */
	display: block;
}

input, textarea, select {
	/* To make sure that all text fields have the same font settings
	By default, textareas have a monospace font */

	/* To give the same size to all text fields */
	width: 100%;
	box-sizing: border-box;
	// background-color: $gray-lighter;
	/* To harmonize the look & feel of text field border */
	border: none;
	border-bottom: 1px solid $gray-base;
}

input, select {
	padding: 10px 0 6px 0;

	&:first-child {
		padding-top: 0;
	}
}

input:focus, textarea:focus, select:focus {
	outline: none;

}

textarea {
	/* To properly align multiline text fields with their labels */
	vertical-align: top;
	border: 1px solid $gray-base;

	/* To give enough room to type some text */
	height: rem-calc(200);

	/* Disable resize */
	resize: none;
}

select {
    color: #757575;
}

// .button {


// }

// button {
// /* This extra margin represent roughly the same space as the space
// between the labels and their text fields */

// }

.form-control {
	padding: 0 15px;
}

.form-btn {
	@include float(right);
	background-color: transparent;
	color: $gray-base;
	border: none;
	font-weight: bold;
	margin-top: 15px;
}
