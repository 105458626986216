.image-gallery {
    $arrowOffset: 25px;

    &__preview {
        .slick-prev {
            left: $arrowOffset;

            [dir="rtl"] & {
                left: auto;
                right: $arrowOffset;
            }
        }

        .slick-next {
            right: $arrowOffset;

            [dir="rtl"] & {
                left: $arrowOffset;
                right: auto;
            }
        }
    }

    &__navigation {
        .slick-slide {
            opacity: 0.5;
            padding: vr(0.3);
            transition: opacity 0.2s ease-in-out;

            &.slick-current {
                opacity: 1;
            }
        }
    }
}