.mm-panels {
    --mm-line-height: 20px;
	--mm-listitem-size: 44px;
	--mm-navbar-size: 44px;
}

$mm_backgroundColor: $brand-shade;
// $mm_listitemIndent: 0;

@import "core/screenreader/jquery.mmenu.screenreader";
@import "core/oncanvas/jquery.mmenu.oncanvas";
@import "core/offcanvas/jquery.mmenu.offcanvas";

@import "extensions/positioning/jquery.mmenu.positioning";
@import "addons/_rtl/jquery.mmenu.rtl";