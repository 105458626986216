.section {
	position: relative;

	&--lg {
		padding: vr(3) 0;

		@include mappy-bp(small) {
			padding: vr(4) 0;
		}
	}

	&--md {
		padding: vr(1) 0;

		@include mappy-bp(small) {
			padding: vr(2) 0;
		}
	}

	&--sm {
		padding: vr(1) 0;

		&-min-md {
			padding: vr(1) 0;

			@include mappy-bp(max medium) {
				padding: 0;
			}
		}
	}

	&--xs {
		padding: vr(1) / 2 0;
	}

	&--full-height {
		height: 100vh;
	}

	&--divider {
		margin: vr(1) 0;
		height: 2px;
		background-color: $brand-primary;
	}

	&--divider-gray {
		margin: vr(1) 0;
		height: 3px;
		background-color: $gray-lighter;
	}

	&--image {
		position: relative;
		height: 350px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	}

	&-anchor {
		// Header's height
		@include mappy-bp(small) {
			margin-top: -100px;
			padding-top: 100px;
		}

		margin-top: -70px;
		padding-top: 70px;
	}
}
