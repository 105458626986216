.footer {
	background: $gray-base;

	@include mappy-bp(small) {
		position: absolute;
		bottom: 0;
		width: 100%;
	}

	a {
		font-size: $font-size-small;
	}

    .copyrights {
        font-size: $font-size-small;
        text-align: right;
        margin-bottom: 0;
	}

	.social-menu {
		@include horizontal-menu(right, 45px);
	}
}
