@mixin clean-menu {
    list-style: none;
    margin: 0;
}

@mixin horizontal-menu($direction: left, $gutter: 15px, $separator: false) {
    @include clean-menu;

    @include text-align($direction);

    > li {
        display: inline-block;
        vertical-align: middle;

        @if $direction == "left" {
            @include margin-right($gutter);
        }
        @else {
            @include margin-left($gutter);
        }

        @if $separator {
            &:after {
                content: if(type-of($separator) == "string", $separator, "|");
                display: inline-block;

                @include padding-left($gutter);
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }
        }
    }
}

@mixin drop-down($padding: 0, $offset: 5) {
    li {
        position: relative;
        padding-bottom: rem-calc($offset);

        .sub-menu {
            @include clean-menu;

            @include left(0);

            min-width: 200px;
            padding: $padding;
            position: absolute;
            white-space: nowrap;
            transition: opacity 150ms linear, visibility 150ms linear, transform 150ms linear;
            opacity: 0;
            visibility: hidden;
            transform: translateY(rem-calc($offset * 2));
            z-index: 10;

            li {
                display: block;

                @include text-align(left);

                a {
                    display: inline-block;
                    width: 100%;
                    padding: rem-calc(5 15);
                }
            }

            .sub-menu {
                @include left(100%);
				top: 0;
				padding: 0;
            }
        }

        &:last-child,
        &.menu-edge {
            .sub-menu {
                @include left(auto);

				@include right(0);
				
				.sub-menu {
					@include right(100%);
				}
            }
        }

        &:hover {
            > .sub-menu {
                opacity: 1;
                visibility: visible;
				transform: translateY(rem-calc($offset));
				
				.sub-menu {
					transform: translateY(0);
				}
            }
        }
    }
}

@mixin justified-menu($text-transform: none) {
    @include horizontal-menu;

    width: 100%;
    display: table;

    > li {
        display: table-cell;
        text-align: center;

        &:first {
            @include text-align(left);
        }

        &:last {
            @include text-align(right);
        }
    }

    a {
        text-transform: $text-transform;
        display: block;
        padding: 10px 10px;
    }
}

@mixin inline-menu {
    @include padding-left(rem-calc(20));
}

@mixin sprite-icon($url, $width, $height, $bg-position-x, $bg-position-y, $left: auto, $right: auto, $center: true, $top: auto, $bottom: auto, $empty: false) {
    position: relative;

    @if $empty {
        width: $width;
        height: $height;
    }

    &:after {
        content: '';
        position: absolute;
        background-image: url($url);
        background-repeat: no-repeat;
        display: block;
        width: $width;
        height: $height;
        background-position: $bg-position-x $bg-position-y;
        left: $left;
        right: $right;

        @if $center {
            top: 50%;
            transform: translateY(-50%);
        }
        @else {
            top: $top;
            bottom: $bottom;
        }
    }
}
