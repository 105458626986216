.hover-box {
	position: relative;
	padding: 100px 0;
	text-align: center;

	&__title {
		margin-bottom: 0;
	}

	a {
		position: relative;
		display: inline-block;

		.gray-circle, .colored-circle {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 60px;
			height: 60px;
			background-color: $gray-lighter;
			border-radius: 50%;
			z-index: -1;
			transition: all 0.3s ease-in-out;
		}

		.colored-circle {
			background-color: yellow;
			opacity: 0;

		}
		&:hover .colored-circle {
			opacity: 1;
			transform: translate(-50%, -50%) scale(4.7);
		}

		&:hover .gray-circle {
			transform: translate(-50%, -50%) scale(4.7);
		}

		// &:after {
		// 	// content:'';
		// 	position: absolute;
		// 	top: 50%;
		// 	left: 50%;
		// 	transform: translate(-50%, -50%);
		// 	width: 60px;
		// 	height: 60px;
		// 	background-color: $gray-lighter;
		// 	border-radius: 50%;
		// 	z-index: -1;
		// 	transition: all 0.3s ease-in-out;
		// }


		&.bg-yellow:hover:after {
				transform: translate(-50%, -50%) scale(4.7);
				background-color: yellow;
		}

		&.expander-link {
			font-weight: bold;

			&:after {
				width: 35px;
				height: 35px;
			}
			&:before {
				content: '';
				position: absolute;
				left: 50%;
				top: calc(100% + 10px);
				transform: translateX(-50%);
				border-left: 10px solid transparent;
				border-right: 10px solid transparent;
				border-top: 10px solid $gray-base;
			}
		}
	}
}


.white-popup {
	position: relative;
	background: yellow;
	// padding: 20px;
	height: 500px;
	width: auto;
	width: 500px;
	margin: 20px auto;
	border-radius: 50%;
}

.popup--content {
	position: absolute;
	text-align: center;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: calc(100% - 130px);

	p {
		margin-bottom: 0;
	}

}

.mfp-close {
	// right: auto;
	left: 50%;
	top: 10%;
	transform: translateX(-50%);
	font-size: 40px;

	&:active {
		top: 10%;
	}
}


.hover-box--two {
	overflow: hidden;
	position: relative;
	height: 285px;
	display: block;
	background-size: cover;
    background-position: center;

	&__image {
		display: none;
	}

	&__content {
		padding: 0 15px;
		position: absolute;
		top: calc(100% - 38px);
		left: 0;
		right: 0;
		background: rgba(white, 0.93);
		padding-top: 5px;
		transition: top 0.2s ease-in-out;
		bottom: 0;
	}

	&:hover .hover-box--two__content {
		top: 150px;
	}

	@include mappy-bp(max medium) {

		background-image: none !important;
		height: auto;

		&__image {
			display: block;
		}

		&__content {
			position: static;
			padding: 15px 0 0 0;
			margin-bottom: 20px;
		}
	}
}

.social-feeds {
	height: 285px;

	@include mappy-bp(max medium) {
		padding-top: 15px;
		height: auto;
	}
}
