.accordion {
    &__heading {
        padding: 0;
    }

    &__title {
		margin-bottom: 0;
		font-weight: bold;
        a {
			display: inline-block;
			border-top: 1px solid black;
            width: 100%;
            padding: (vr(1) / 2) 0;
            transition: background-color 0.2s ease-in-out;


            &:after {
				display: inline-block;
				content: '+';
				@include float(right);
				transition: transform 0.2s ease-in-out;
            }

            // @include arrow($direction: "down", $size: 15, $color: $white);

            &[aria-expanded="true"] {
				&:after {
					transform: rotate(45deg);
				}
            }
		}

		&:last-of-type {
			border-bottom: 1px solid black;
		}

        // &:first-child {
        //     margin-top: 0;
        // }
    }

    &__content {
        overflow: hidden;

        .content-wrapper {
			padding: vr(1);
			background-color: $gray-base;
			color: $white;

			a {
				text-decoration: underline;
				color: $white;

				&:hover {
					color: $white;
				}
			}
        }
    }
}
