.wwd-colorHover {
    background: #b1afab;
    padding: 20px 30px;
    border: 14px solid #ffffff;
    box-shadow: 2px 2px 10px 2px #afafaf47;
    min-height: 390px;
    margin-bottom: 30px;
    color: #464646;
    text-align: center;
    transition: all 0.5s ease-out 0s;
    i{
        text-align: center;
        display: block;
        font-size: 47px;
        color: #464646;
    }
    h3{
        color: #464646;  
    }
    &:hover{
        background: #464646;
        color: #ffffff;
        cursor: pointer;
        i, h3{
            color: #ffffff;
        }
    }
}