img {
	max-width: 100%;
}

.bg-image {
	background-size: cover;
	background-position: center;

	&--gradient {
		background-repeat: no-repeat;
		background-size: cover;
		background-position: right top;
		transition: background 0.2s ease-in-out;

		.bg-choices {
			display: none;
		}
	}
}
