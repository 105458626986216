html,
body {
	min-height: 100%;
}

body {
	overflow-x: hidden;
}

// Fixes the header flash in loading screen;
.no-js .mm-page {
	z-index: 2;
}

.page-wrapper {
	position: relative;
	overflow-y: hidden;
	min-height: 100vh;
	padding-top: rem-calc(105);
	transition: padding 150ms linear, transform .3s ease-in-out;

	@include mappy-bp(large) {
		padding-top: rem-calc(141);
	}

	@include mappy-bp(small) {
		padding-bottom: 221px;
	}
}

.no-free-will {
	position: relative
	;
	@include mappy-bp(medium) {
		max-width: 940px;
		margin: auto;
	}
}

@include rtl {
	.mfp-content {
		text-align: right;
	}

	.mfp-close {
		right: auto;
		left: 0;
	}
}

.container--xlg {
	@include mappy-bp(1330px) {
    	width: 1300px;
	}
}
