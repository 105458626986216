$slick-font-path: "../fonts/";

@function image-url($url) {
    @return url('../img/' + $url);
}

@import 'slick.scss';
@import 'slick-theme.scss';

.slick-slide {

    &:focus {
        outline: 0;
    }
}
